import axios from 'axios';

const api = axios.create({
  baseURL: window.location.hostname === 'localhost' ? 'http://localhost:3007/api/v1' : 'https://oxotime.oxon.ch/api/v1',
  headers: {
    'accept': 'application/json'
  }
});

export function updateApiHeaders(user) {
  if (user) {
    api.defaults.headers.common['x-auth-user-email'] = user.profile.email;
  } else {
    console.log('header x-auth-user-email auf null!')
    api.defaults.headers.common['x-auth-user-email'] = null;
  }
}

export default api;
